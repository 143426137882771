 
<template>
	<van-popup v-model="popShow" position="bottom" @closed="popupClosed" @opened="popupOpened">
		<div class="datepicker-topinfo">
			<div class="datepicker-topinfo__leftbtn" style="font-size:20px"  @click="btnCancel">{{ hiddenConfirm ? "取消" : "返回修改" }}</div>
			<div class="datepicker-topinfo__rightbtn" style="font-size:20px"  @click="btnConfirm">{{ hiddenConfirm ? "完成" : "确认" }}</div>
			<div class="datepicker-topinfo__class" v-show="hiddenConfirm">
				<div class="item" :class="{ 'item--active': !lunarTab }" @click="solarLunarChange('solar')">公历</div>
				<div class="item" :class="{ 'item--active': lunarTab }" @click="solarLunarChange('lunar')">农历</div>
			</div>
			<div class="datepicker-topinfo__text" v-show="!hiddenConfirm">确认日期</div>
		</div>
		<div class="datepicker-content">
			<van-picker :columns="columns" item-height="40"  ref="vanPicker" @change="pickerChange" />
			<div class="datepicker-confirm" v-show="!hiddenConfirm">
				<p class="tip">请确认选择的时间是否正确</p>
				<p class="p">{{ returnData.solarStr }}</p>
				<p class="p">{{ returnData.lunarStr }}</p>
			</div>
		</div>
	</van-popup>
</template>

<script>
import '@vant/touch-emulator';
import solarLunar from "./solarlunar";
import { Picker, Popup } from "vant";
export default {
	name: "rui-datepicker",
	components: {
		VanPicker: Picker,
		VanPopup: Popup
	},
	model: {
		prop: 'show',
		event: 'modelChange'
	},
	props: {
		// 是否显示
		show: {
			type: Boolean,
			default: false
		},
		//指定最小开始时间(新历)
		start: {
			type: String,
			default: ""
		},
		//指定最大开始时间(新历)
		end: {
			type: String,
			default: ""
		},
		//默认日期(新历)
		date: {
			type: String,
			default: "2021-7-15"
		},
		//默认时辰,未知
		hour: {
			type: Number,
			default: -1
		},
		//日期，input-name
		dateName: {
			type: String,
			default: "birthday"
		},
		//时辰，input-name
		hourName: {
			type: String,
			default: "hour"
		},
		//是否需要确认
		isConfirm: {
			type: Boolean,
			default: false
		},
		//是否有时辰选项
		showHour: {
			type: Boolean,
			default: true
		},
		//默认展示农历
		isLunar: {
			type: Boolean,
			default: false
		}
	},
	data() {
		let solarMaxDate 
		console.log(this.$route.path)
		switch (this.$route.path) {
			case '/fleeting_time_fortune':
				solarMaxDate = [new Date().getFullYear() - 10, 12, 31];
				break;
		
			default:
				solarMaxDate = [new Date().getFullYear() + 1, 12, 31];
				break;
		}
		return {
			columns: [],
			popShow: false,
			lunarTab: true,
			hiddenConfirm: true,
			isClickConfirm: false,
			returnData: {},
			inputData: {},
			solarMinDate: [1940, 5, 10], //新历最小
			solarMaxDate: solarMaxDate, //新历最大
			lunarMinDate: [1940, 2, 10, false], //农历最小，第四个参数为是否闰年
			lunarMaxDate: [2020, 12, 17, false] //农历最大
		};
	},
	watch: {
		// 实现弹窗显示双向绑定
		show: function(val) {
			this.popShow = val;
		}
	},
	mounted() {
		// 设置最大最小值
		const startDateArr = this.start.split("-");
		const endDateArr = this.end.split("-");
		this.solarMinDate = startDateArr.length == 3 ? startDateArr : this.solarMinDate;
		this.solarMaxDate = endDateArr.length == 3 ? endDateArr : this.solarMaxDate;
		const returnMinLunar = solarLunar.solar2lunar(this.solarMinDate[0], this.solarMinDate[1], this.solarMinDate[2]);
		const returnMaxLunar = solarLunar.solar2lunar(this.solarMaxDate[0], this.solarMaxDate[1], this.solarMaxDate[2]);
		this.lunarMinDate = [returnMinLunar.lYear, returnMinLunar.lMonth, returnMinLunar.lDay, returnMinLunar.isLeap];
		this.lunarMaxDate = [returnMaxLunar.lYear, returnMaxLunar.lMonth, returnMaxLunar.lDay, returnMaxLunar.isLeap];
		// console.log('returnMaxLunar',new Date().getFullYear() -10)
		
		
		// 默认农历
		if (this.isLunar == true) {
			//载入农历数据
			switch (this.$route.path) {
				case '/fleeting_time_fortune':
					this.initlunar('1990-07-15', this.hour);
					break;
			
				default:
					this.initlunar(this.date, this.hour);
					break;
			}
			// this.initlunar(this.date, this.hour);
		} else {
			//载入公历数据
			switch (this.$route.path) {
				case '/fleeting_time_fortune':
					this.initsolar('1990-07-15', this.hour);
					break;
			
				default:
					this.initsolar(this.date, this.hour);
					break;
			}
			// this.initsolar(this.date, this.hour);
		}
		// 设置配置，显示组件
		this.lunarTab = this.isLunar;
	},
	computed: {
		// 是否已经选择
		isSelect() {
			return JSON.stringify(this.inputData) === "{}" ? false : true;
		}
	},
	methods: {
		// 弹出层打开，防止第一次渲染拿不到ref实例
		popupOpened() {
			setTimeout(this.setReturnData, 50)
		},
		// 弹出层关闭
		popupClosed() {
			this.$emit("modelChange", false);
		},
		// 返回中文农历名
		getLunarName(type, number) {
			const monthArr = ["正月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "腊月"];
			const dayArr = ["初一", "初二", "初三", "初四", "初五", "初六", "初七", "初八", "初九", "初十", "十一", "十二", "十三", "十四", "十五", "十六", "十七", "十八", "十九", "二十", "廿一", "廿二", "廿三", "廿四", "廿五", "廿六", "廿七", "廿八", "廿九", "三十", "三十一"];
			const hourArr = ["子", "丑", "丑", "寅", "寅", "卯", "卯", "辰", "辰", "巳", "巳", "午", "午", "未", "未", "申", "申", "酉", "酉", "戌", "戌", "亥", "亥", "子"];
			if (type === "month") return monthArr[number - 1];
			if (type === "day") return dayArr[number - 1];
			if (type === "hour") return hourArr[number];
		},
		// 公农历切换
		solarLunarChange(type) {
			const thisData = this.returnData;
			// 农历下点击公历切换
			if (this.lunarTab !== true && type == "lunar") {
				this.lunarTab = true;
				this.initlunar(thisData.year + "-" + thisData.month + "-" + thisData.day, thisData.hour);
			}
			// 公历下点击农历切换
			if (this.lunarTab === true && type == "solar") {
				this.lunarTab = false;
				this.initsolar(thisData.year + "-" + thisData.month + "-" + thisData.day, thisData.hour);
			}
			setTimeout(this.setReturnData, 50)
		},
		// 设置返回的数据
		setReturnData() {
			const selectArr = this.$refs.vanPicker.getIndexes();
			let thisDateJson = {};
			thisDateJson.hour = this.showHour === false ? "" : selectArr[3] - 1;
			thisDateJson.hour = thisDateJson.hour < 0 ? "" : thisDateJson.hour;
			if (this.lunarTab === true) {
				//农历下
				thisDateJson.lYear = selectArr[0] + this.lunarMinDate[0];
				const leapMonth = solarLunar.leapMonth(selectArr[0] + this.lunarMinDate[0]);
				if (leapMonth > 0) {
					thisDateJson.lMonth = selectArr[1] >= leapMonth ? selectArr[1] : selectArr[1] + 1;
				} else {
					thisDateJson.lMonth = selectArr[1] + 1;
				}
				thisDateJson.lDay = selectArr[2] + 1;
				thisDateJson.isLeap = leapMonth > 0 && selectArr[1] == leapMonth ? true : false;
				if (thisDateJson.isLeap == true) {
					thisDateJson.lunarStr =
						"农历:" +
						thisDateJson.lYear +
						"年闰" +
						this.getLunarName("month", thisDateJson.lMonth) +
						"" +
						this.getLunarName("day", thisDateJson.lDay);
				} else {
					thisDateJson.lunarStr =
						"农历:" +
						thisDateJson.lYear +
						"年" +
						this.getLunarName("month", thisDateJson.lMonth) +
						"" +
						this.getLunarName("day", thisDateJson.lDay);
				}
				// 公历数据
				let solarData = solarLunar.lunar2solar(thisDateJson.lYear, thisDateJson.lMonth, thisDateJson.lDay, thisDateJson.isLeap);
				thisDateJson.year = solarData.cYear;
				thisDateJson.month = solarData.cMonth;
				thisDateJson.day = solarData.cDay;
				thisDateJson.solarStr = "公历:" + thisDateJson.year + "年" + thisDateJson.month + "月" + thisDateJson.day + "日";
			} else {
				//公历下
				thisDateJson.year = selectArr[0] + this.solarMinDate[0];
				thisDateJson.month = selectArr[1] + 1;
				thisDateJson.day = selectArr[2] + 1;
				thisDateJson.solarStr = "公历:" + thisDateJson.year + "年" + thisDateJson.month + "月" + thisDateJson.day + "日";
				// 农历数据
				let lunarData = solarLunar.solar2lunar(thisDateJson.year, thisDateJson.month, thisDateJson.day);
				thisDateJson.lYear = lunarData.lYear;
				thisDateJson.lMonth = lunarData.lMonth;
				thisDateJson.lDay = lunarData.lDay;
				thisDateJson.isLeap = lunarData.isLeap;
				if (thisDateJson.isLeap == true) {
					thisDateJson.lunarStr =
						"农历:" +
						thisDateJson.lYear +
						"年闰" +
						this.getLunarName("month", thisDateJson.lMonth) +
						"" +
						this.getLunarName("day", thisDateJson.lDay);
				} else {
					thisDateJson.lunarStr =
						"农历:" +
						thisDateJson.lYear +
						"年" +
						this.getLunarName("month", thisDateJson.lMonth) +
						"" +
						this.getLunarName("day", thisDateJson.lDay);
				}
			}
			// 判断是否有选择时辰
			if (this.showHour) {
				thisDateJson.solarStr += " " + (thisDateJson.hour === "" ? "时辰未知" : thisDateJson.hour + "时");
				thisDateJson.lunarStr += " " + (thisDateJson.hour === "" ? "时辰未知" : this.getLunarName("hour", thisDateJson.hour) + "时");
			}
			//判断当前模式返回thisStr
			if (this.lunarTab === true) {
				thisDateJson.thisStr = thisDateJson.lunarStr;
			} else {
				thisDateJson.thisStr = thisDateJson.solarStr;
			}
			this.returnData = thisDateJson;
		},
		// 切换数据
		pickerChange(picker) {
			const selectArr = picker.getIndexes();
			// 判断在公/农历下
			if (this.lunarTab === true) {
				this.pickerLunarChange(selectArr);
			} else {
				this.pickerSolarChange(selectArr);
			}
			setTimeout(this.setReturnData, 50)
			this.$emit("change", this.returnData);
		},
		// 农历切换
		pickerLunarChange(selectArr) {
			const minDate = this.lunarMinDate;
			const maxDate = this.lunarMaxDate;
			// 该年是否有闰月，0没有
			const leapMonth = solarLunar.leapMonth(selectArr[0] + minDate[0]);
			const oldMonthArr = this.columns[1].values;
			let monthArr = [],
				dayArr = [];
			// 月份数据
			for (let i = 1; i <= 12; i++) {
				monthArr.push(this.getLunarName("month", i));
				// 判断是否有闰月
				if (leapMonth > 0 && i == leapMonth) {
					monthArr.push("闰" + this.getLunarName("month", i));
				}
			}
			// 日期数组
			let maxDay;
			//判断是否有闰月
			if (leapMonth > 0) {
				if (selectArr[1] < leapMonth) {
					//月份小于闰月，+1
					maxDay = solarLunar.monthDays(selectArr[0] + minDate[0], selectArr[1] + 1);
				} else {
					if (selectArr[1] == leapMonth) {
						maxDay = solarLunar.leapDays(selectArr[0] + minDate[0], leapMonth);
					} else {
						// 月份大于闰月
						maxDay = solarLunar.monthDays(selectArr[0] + minDate[0], selectArr[1]);
					}
				}
			} else {
				//没有闰月+1 (有闰月切换没闰月最大值处理)
				let thisMonth = selectArr[1] + 1 > monthArr.length ? monthArr.length : selectArr[1] + 1;
				maxDay = solarLunar.monthDays(selectArr[0] + minDate[0], thisMonth);
			}
			for (let i = 1; i <= maxDay; i++) {
				dayArr.push(this.getLunarName("day", i));
			}
			// 年切换月份位置修正：有闰年 -> 没闰年
			if (oldMonthArr.length > monthArr.length) {
				let oldLeapMonth = 0;
				for (let i = 0, max = oldMonthArr.length; i < max; i++) {
					if ("" + oldMonthArr[i].indexOf("闰") >= 0) {
						oldLeapMonth = i;
					}
				}
				selectArr[1] = selectArr[1] + 1 > oldLeapMonth ? selectArr[1] - 1 : selectArr[1];
			}
			// 年份切换月份位置修正：没闰年 -> 有闰年
			if (oldMonthArr.length < monthArr.length) {
				selectArr[1] = selectArr[1] + 1 > leapMonth ? selectArr[1] + 1 : selectArr[1];
			}
			// 判断是否超出月份最大值(有闰年切换没闰年的情况)
			selectArr[1] = selectArr[1] >= monthArr.length ? monthArr.length - 1 : selectArr[1];
			// 判断是否超出日期最大值
			selectArr[2] = selectArr[2] >= maxDay ? maxDay - 1 : selectArr[2];
			// 判断到达年份最小
			if (selectArr[0] == 0) {
				// 有无闰月
				if (leapMonth > 0) {
					selectArr[1] = selectArr[1] < minDate[1] ? minDate[1] : selectArr[1];
					// 日期最大值
					if (selectArr[1] == minDate[1] && selectArr[2] + 1 < minDate[2]) {
						selectArr[2] = minDate[2] - 1;
					}
				} else {
					selectArr[1] = selectArr[1] < minDate[1] - 1 ? minDate[1] - 1 : selectArr[1];
					// 日期最大值
					if (selectArr[1] == minDate[1] - 1 && selectArr[2] + 1 < minDate[2]) {
						selectArr[2] = minDate[2] - 1;
					}
				}
			}
			// 判断到达年份最大
			if (selectArr[0] + minDate[0] == maxDate[0]) {
				// 有无闰月
				if (leapMonth > 0) {
					selectArr[1] = selectArr[1] > maxDate[1] ? maxDate[1] : selectArr[1];
					// 日期最大值
					if (selectArr[1] == maxDate[1] && selectArr[2] + 1 > maxDate[2]) {
						selectArr[2] = maxDate[2] - 1;
					}
				} else {
					selectArr[1] = selectArr[1] > maxDate[1] - 1 ? maxDate[1] - 1 : selectArr[1];
					// 日期最大值
					if (selectArr[1] == maxDate[1] - 1 && selectArr[2] + 1 > maxDate[2]) {
						selectArr[2] = maxDate[2] - 1;
					}
				}
			}
			// 更新数据
			this.columns.splice(1, 2, {
				values: monthArr,
				defaultIndex: selectArr[1]
			},{
				values: dayArr,
				defaultIndex: selectArr[2]
			})
		},
		// 公历切换
		pickerSolarChange(selectArr) {
			const minDate = this.solarMinDate;
			const maxDate = this.solarMaxDate;
			let dayArr = [];
			let maxDay = solarLunar.solarDays(selectArr[0] + minDate[0], selectArr[1] + 1);
			for (let i = 1; i <= maxDay; i++) {
				dayArr.push(i);
			}
			// 判断是否超出日期最大值
			selectArr[2] = selectArr[2] >= maxDay ? maxDay - 1 : selectArr[2];
			// 判断月份是否到达最小
			if (selectArr[0] == 0 && selectArr[1] + 1 < minDate[1]) {
				selectArr[1] = minDate[1] - 1;
			}
			//判断日期到达最小
			if (selectArr[0] == 0 && selectArr[1] + 1 == minDate[1] && selectArr[2] + 1 < minDate[2]) {
				selectArr[2] = minDate[2] - 1;
			}
			// 判断月份是否到达最大
			if (selectArr[0] + minDate[0] == maxDate[0] && selectArr[1] + 1 >= maxDate[1]) {
				selectArr[1] = maxDate[1] - 1;
			}
			// 判断日期是否到达最大
			if (selectArr[0] + minDate[0] == maxDate[0] && selectArr[1] + 1 == maxDate[1] && selectArr[2] + 1 >= maxDate[2]) {
				selectArr[2] = maxDate[2] - 1;
			}
			// 更新日期+最小值选择
			this.columns.splice(2, 1, {
				values: dayArr,
				defaultIndex: selectArr[2]
			})
		},
		// 填充农历数据
		initlunar(date, hour) {
			const minDateArr = this.lunarMinDate;
			const maxDateArr = this.lunarMaxDate;
			const dateArr = date.split("-");
			// 转换公历to农历
			const lunarData = solarLunar.solar2lunar(dateArr[0], dateArr[1], dateArr[2]);
			// 该年是否有闰月，0没有
			const leapMonth = solarLunar.leapMonth(lunarData.lYear);
			let yearArr = [],
				monthArr = [],
				dayArr = [];
			// 年份数组
			for (let i = minDateArr[0]; i <= maxDateArr[0]; i++) {
				yearArr.push(i);
			}
			// 月份数组
			for (let i = 1; i <= 12; i++) {
				monthArr.push(this.getLunarName("month", i));
				// 判断是否有闰月
				if (leapMonth > 0 && i == leapMonth) {
					monthArr.push("闰" + this.getLunarName("month", i));
				}
			}
			// 日期数组
			let maxDay;
			// 该日期是否是闰月
			if (lunarData.isLeap) {
				maxDay = solarLunar.leapDays(dateArr[0], dateArr[1]);
			} else {
				maxDay = solarLunar.monthDays(dateArr[0], dateArr[1]);
			}
			for (let i = 1; i <= maxDay; i++) {
				dayArr.push(this.getLunarName("day", i));
			}
			// 整合columns数据
			let columnsArr = [
				{
					values: yearArr,
					defaultIndex: lunarData.lYear - minDateArr[0]
				},
				{
					values: monthArr,
					defaultIndex: leapMonth > 0 && leapMonth <= lunarData.lMonth ? lunarData.lMonth : lunarData.lMonth - 1
				},
				{
					values: dayArr,
					defaultIndex: lunarData.lDay - 1
				}
			];
			if (this.showHour) {
				let hourArr = ["未知"];
				for (let i = 0; i <= 23; i++) {
					let hour
					let shi = this.getLunarName("hour", i)
					if(i < 10){
						hour = "0"+i+":00-0"+i+":59"+"("+shi+")"
					}else{
						hour = i+":00-"+i+":59"+"("+shi+")"
					}
					hourArr.push(hour);
				}
				columnsArr.push({
					values: hourArr,
					defaultIndex: (hour === "" ? -1 : parseInt(hour)) + 1
				});
			}
			this.columns = columnsArr;
		},
		// 填充公历数据
		initsolar(date, hour) {
			const dateArr = date.split("-");
			const minDateArr = this.solarMinDate;
			const maxDateArr = this.solarMaxDate;
			let yearArr = [],
				monthArr = [],
				dayArr = [];
			// 年份数组
			for (let i = minDateArr[0]; i <= maxDateArr[0]; i++) {
				yearArr.push(i);
			}
			// 月份数组
			for (let i = 1; i <= 12; i++) {
				monthArr.push(i);
			}
			// 日期数组
			let maxDay = solarLunar.solarDays(dateArr[0], dateArr[1]);
			for (let i = 1; i <= maxDay; i++) {
				dayArr.push(i);
			}
			// 整合columns数据
			let columnsArr = [
				{
					values: yearArr,
					defaultIndex: dateArr[0] - minDateArr[0]
				},
				{
					values: monthArr,
					defaultIndex: dateArr[1] - 1
				},
				{
					values: dayArr,
					defaultIndex: dateArr[2] - 1
				}
			];
			// 时辰数组
			if (this.showHour) {
				let hourArr = ["未知"];
				for (let i = 0; i <= 23; i++) {
					let hour
					let shi = this.getLunarName("hour", i)
					if(i < 10){
						hour = "0"+i+":00-0"+i+":59"+"("+shi+")"
					}else{
						hour = i+":00-"+i+":59"+"("+shi+")"
					}
					hourArr.push(hour);
				}
				columnsArr.push({
					values: hourArr,
					defaultIndex: (hour === "" ? -1 : parseInt(hour)) + 1
				});
			}
			this.columns = columnsArr;
		},
		// 确认完成
		btnConfirm() {
			// 判断是否需要确认
			if (this.isConfirm) {
				// 判断是否在确认步骤[完成&确认]
				if (this.hiddenConfirm === false) {
					this.popShow = false;
					this.hiddenConfirm = true;
					this.inputData = this.returnData;
					this.$emit("confirm", this.inputData);
				} else {
					this.hiddenConfirm = false;
				}
			} else {
				this.popShow = false;
				this.hiddenConfirm = true;
				this.inputData = this.returnData;
				this.$emit("confirm", this.inputData);
			}
		},
		// 点击取消
		btnCancel() {
			// 判断是否在确认步骤[返回修改&取消]
			if (this.hiddenConfirm === false) {
				this.hiddenConfirm = true;
			} else {
				this.popShow = false;
			}
		}
	}
};
</script>

<style lang="scss">
// topinfo
.datepicker-topinfo {
	position: relative;
	height: 50px;
	text-align: center;
	&::after {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 1px;
		background-color: #f6f6f6;
		transform: scaleY(0.5);
		transform-origin: 0 0;
	}
	border-bottom: 1px solid #f6f6f6;
	&__class {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		.item {
			display: inline-block;
			line-height: 28px;
			font-size: 14px;
			padding: 0 20px;
			text-align: center;
			color: #B95353;
			border: 1px solid #B95353;
			&:first-child {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
			&:last-child {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
			&--active {
				background-color: #B95353;
				color: #fff;
			}
		}
	}
	&__text {
		line-height: 50px;
		font-size: 17px;
		color: #363837;
	}
	&__leftbtn,
	&__rightbtn {
		position: absolute;
		top: 0;
		line-height: 50px;
		font-size: 16px;
		padding: 0 10px;
	}
	&__leftbtn {
		left: 0;
	}
	&__rightbtn {
		color: #B95353;
		right: 0;
	}
	.datepicker-topinfo__class{
		top: 30%;
		// position: absolute;
		// height: 50px;
   		// top: -.3rem;
		// left: 0;
		// right: 0;
		// transform:none;
		// z-index: 1000;
	}
}
// 滑块内容
.datepicker-content {
	position: relative;
}
// 确认
.datepicker-confirm {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	background-color: #fff;
	z-index: 3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	p{
		padding: 4px 0;
		margin: 0;
	}
	.tip {
		color: #666;
		margin-bottom: 6px;
	}
	.p {
		color: #B95353;
	}
}

.van-picker-column{
	font-size:14px !important;
}

.van-picker-column:nth-child(4){
	flex:1.5
}
.van-picker-column:nth-child(2){
	flex:0.8
}
.van-picker-column:nth-child(3){
	flex:0.8
}
</style>

