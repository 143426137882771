//时间格式化
const timeFormatting = (date_,format_) => {
    let date,time_formatting;
    let format = format_;
    switch (Object.prototype.toString.call(date_).slice(8,-1)) {
        case "Undefined":
            date = new Date() 
            break;
        case "Date":
            date = date_ 
            break;
        case "String":
                date = new Date(date_) 
                if(date.toString() === 'Invalid Date'){
                    date = new Date()
                }
            break;
    
        default:
            break;
    }
    let Y = date.getFullYear();    // 获取完整的年份(4位,1970-????)
    let M = date.getMonth();       // 获取当前月份(0-11,0代表1月)
    let D = date.getDate();        // 获取当前日(1-31)
        date.getDay();         // 获取当前星期X(0-6,0代表星期天)
        date.getTime();        // 获取当前时间(从1970.1.1开始的毫秒数)
    let h = date.getHours();       // 获取当前小时数(0-23)
    let m = date.getMinutes();     // 获取当前分钟数(0-59)
    let s = date.getSeconds();     // 获取当前秒数(0-59)

    switch (format) {
        case undefined :
            switch (date_) {
                case 'YYYY-MM-DD':
                    time_formatting  = `${Y}-${(M + 1) < 10 ? "0" + (M + 1) : (M + 1)}-${D < 10 ? "0" + D : D}`
                    break;
                case 'YYYY-MM':
                    time_formatting  = `${Y}-${(M + 1) < 10 ? "0" + (M + 1) : (M + 1)}`
                    break;
                case 'MM-DD':
                    time_formatting  = `${(M + 1) < 10 ? "0" + (M + 1) : (M + 1)}-${D < 10 ? "0" + D : D}`
                    break;
                case 'YYYY/MM/DD':
                    time_formatting  = `${Y}/${(M + 1) < 10 ? "0" + (M + 1) : (M + 1)}/${D < 10 ? "0" + D : D}`
                    break;
                case 'YYYY/MM':
                    time_formatting  = `${Y}/${(M + 1) < 10 ? "0" + (M + 1) : (M + 1)}`
                    break;
                case 'MM/DD':
                    time_formatting  = `${(M + 1) < 10 ? "0" + (M + 1) : (M + 1)}/${D < 10 ? "0" + D : D}`
                    break;
                case 'YYYY':
                    time_formatting  = `${Y}`
                    break;
                case 'MM':
                    time_formatting  = `${(M + 1) < 10 ? "0" + (M + 1) : (M + 1)}`
                    break;
                case 'DD':
                    time_formatting  = `${D < 10 ? "0" + D : D}`
                    break;
                case 'HH:mm:ss':
                    time_formatting = `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`
                    break;
                case 'HH:mm':
                    time_formatting = `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}`
                    break;
                case 'mm:ss':
                    time_formatting = `${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`
                    break;
                case 'HH':
                    time_formatting = `${h < 10 ? "0" + h : h}`
                    break;
                case 'mm':
                    time_formatting = `${m < 10 ? "0" + m : m}`
                    break;
                case 'ss':
                    time_formatting = `${s < 10 ? "0" + s : s}`
                    break;
                default:
                    time_formatting  = `${Y}-${(M + 1) < 10 ? "0" + (M + 1) : (M + 1)}-${D < 10 ? "0" + D : D}`  
                    break;
            }
            break;
        case 'YYYY-MM-DD':
            time_formatting  = `${Y}-${(M + 1) < 10 ? "0" + (M + 1) : (M + 1)}-${D < 10 ? "0" + D : D}`
            break;
        case 'YYYY-MM':
            time_formatting  = `${Y}-${(M + 1) < 10 ? "0" + (M + 1) : (M + 1)}`
            break;
        case 'MM-DD':
            time_formatting  = `${(M + 1) < 10 ? "0" + (M + 1) : (M + 1)}-${D < 10 ? "0" + D : D}`
            break;
        case 'YYYY/MM/DD':
            time_formatting  = `${Y}/${(M + 1) < 10 ? "0" + (M + 1) : (M + 1)}/${D < 10 ? "0" + D : D}`
            break;
        case 'YYYY/MM':
            time_formatting  = `${Y}/${(M + 1) < 10 ? "0" + (M + 1) : (M + 1)}`
            break;
        case 'MM/DD':
            time_formatting  = `${(M + 1) < 10 ? "0" + (M + 1) : (M + 1)}/${D < 10 ? "0" + D : D}`
            break;
        case 'YYYY':
            time_formatting  = `${Y}`
            break;
        case 'MM':
            time_formatting  = `${(M + 1) < 10 ? "0" + (M + 1) : (M + 1)}`
            break;
        case 'DD':
            time_formatting  = `${D < 10 ? "0" + D : D}`
            break;
        case 'HH:mm:ss':
            time_formatting = `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`
            break;
        case 'HH:mm':
            time_formatting = `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}`
            break;
        case 'mm:ss':
            time_formatting = `${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`
            break;
        case 'HH':
            time_formatting = `${h < 10 ? "0" + h : h}`
            break;
        case 'mm':
            time_formatting = `${m < 10 ? "0" + m : m}`
            break;
        case 'ss':
            time_formatting = `${s < 10 ? "0" + s : s}`
            break;
        default:
            break;
    }
    return time_formatting
}

export default timeFormatting


// console.log(new Date('2021/08/01'))

//使用------------------------------------------使用
// timeFormatting(date,format)
// date:传入的时间
// format:要格式化的时间格式
//date 参数：new Data()、2021-10-20、2021/10/25
//format 参数：YYYY-MM-DD、YYYY/MM/DD、HH:mm:ss、YYYY-MM,等等
//  YYYY:年
//  MM:月
//  DD:日
//  HH:时
//  mm:分
//  ss:秒

//示例：timeFormatting('2019-1-5',YYYY-MM-DD);//返回2019-01-05
//示例：timeFormatting(new Date(),YYYY/MM/DD);//返回2019/01/05 (当前日期的YYYY/MM/DD格式)
//示例：timeFormatting();//返回当前日期的YYYY-MM-DD格式（不传参数）
