<template>
    <div :style="copyrightInformation_color_style">
        <p v-if="llwh" class="text_p">
            版权信息<br>
            湖南亮亮文化传媒有限公司<br>
            长沙市开福区月湖街道鸭子铺路1号1房2室<br>
            电话：13291802991
        </p>
        <p v-if="ytxx" class="text_p">
            版权信息<br>
            长沙亚汰信息咨询有限公司<br>
            长沙市雨花区井湾子街道湘府东路二段258号<br>
            电话：18975441215
        </p>
        <p v-if="dscm" class="text_p">
            版权信息<br>
            武汉道森梯媒文化传播有限公司<br>
            武汉市徐东大街88号<br>
            电话：18181818181
        </p>
        <p v-if="fxcm" class="text_p">
            版权信息<br>
            山东纷享团电子商务有限公司 <br>
            山东省济南市历城区工业北路21号<br>
            电话：18975441215
        </p>
        <p v-if="rm" class="text_p">
            版权信息<br>
            深圳市大白网络科技有限公司 <br>
            粤ICP备19105866号<br>
            vivo非服务提供商，服务内容与条款以广告主内容为准<br>
            电话：18975441215
        </p>
        <p v-if="zy" class="text_p">
            版权信息<br>
            杭州智以类聚科技有限公司<br>
            浙江省杭州市余杭区五常街道期望铭座B座305<br>
            电话：18975441215
        </p>
        <p v-if="rongming" class="text_p">
            版权信息<br>
            上海容铭信息科技有限公司<br>
            上海市杨浦区抚顺路360号<br>
            电话：18975441215
        </p>
        <p v-if="qida" class="text_p">
            版权信息<br>
            杭州其大无外网络科技有限公司<br>
            浙江省杭州市余杭区仓前街道良睦路1288号7号楼103-5、6室<br>
            电话：15695165587
        </p>
        <p v-if="hjzn" class="text_p">
            版权信息<br>
            绍兴恒健智能科技有限公司<br>
            浙江省绍兴市柯桥区齐贤街道屠家娄95号<br>
            电话：15899582588
        </p>
        <p v-if="hjzn10" class="text_p">
            版权信息<br>
            绍兴恒健智能科技有限公司旗下网站恒健国学文化主要从事人工智能理论与算法软件开发<br>
            浙江省绍兴市柯桥区齐贤街道屠家娄95号<br>
            电话：15899582588
        </p>
        <p v-if="sxytxx" class="text_p">
            版权信息<br>
            图易(绍兴)信息技术有限公司<br>
            浙江省绍兴市柯桥区柯桥街道群贤路2003号1801-16室<br>
            电话：15899582588
        </p>
        <p v-if="whtyxx" class="text_p">
            版权信息<br>
            图易（武汉）信息技术有限公司<br>
            湖北省武汉市东湖新技术开发区光谷大道70号现代光谷世贸中心D栋14层<br>
            电话：13237170069
        </p>


        <p v-if="whqp" class="text_p">
            版权信息<br>
            武汉圈谱文化传播有限公司<br>
            武昌区徐家棚街徐东大街西侧联发九都国际<br>
            电话：15171491487
        </p>
        <p v-if="tjytxwl" class="text_p">
            版权信息<br>
            天津昱通祥网络科技有限公司<br>
            天津市和平区南市街南市大街与福安大街交口天汇广场2-1102-3<br>
            电话：18931051644
        </p>
        <p v-if="gzjhkj" class="text_p">
            版权信息<br>
            贵州均好科技有限公司<br>
            经营范围：商务信息咨询<br>
            贵阳市观山湖区林城西路摩根中心B座第11层B-11-30号<br>
            电话：13024279188
        </p>
       
        <p v-if="gzqqhlw" class="text_p">
            版权信息<br>
            贵阳青青互联网科技有限公司<br>
            经营范围：信息咨询服务<br>
            贵州省贵阳市观山湖区<br>
            电话：13026987414
        </p>
        <p v-if="cqglwl" class="text_p">
            版权信息<br>
            重庆庚霖网络科技有限公司<br>
            经营范围：信息咨询服务<br>
            重庆市南岸区弹子石街道泰昌路68号2幢10-19 <br>
            电话：18111899892
        </p>
        <p v-if="tjxzywlkj" class="text_p">
            版权信息<br>
            天津星之缘网络科技有限公司<br>
            天津市河西区解放南路与浦口道交口东南侧创展大厦16层 <br>
            电话：17754567288
        </p>
        <!--  -->
        <p v-if="jxxskj" class="text_p">
            版权信息<br>
            江西先索科技有限公司<br>
            
        </p>
        <p v-if="jxhtxxjs" class="text_p">
            版权信息<br>
            江西好推信息技术服务有限公司<br>
        </p>
    </div>
</template>

<script>
// import homeMarriageTest from "../mixin/homeMarriageTest"
export default {
    // mixins:[homeMarriageTest],
    data() {
        return{
            copyrightInformation_color_style:'color: #FBEC7B;'
        }
    },
    created() {
        this.copyrightInformationInitStyle()
    },
     computed:{
       
            // 亮亮文化
            llwh(){
                let from = this.$route.query.from;
				let allowfrom = ['xa1','xa2','xa3','xk1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
            // 亚汰信息
            ytxx(){
                let from = this.$route.query.from;
				let allowfrom = ['xb1','xb2','xb3'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
             // 道森
            dscm(){
                let from = this.$route.query.from;
				let allowfrom = ['xc1','xc2','xc3','xc4','xc5','xs1','xs2','xs3'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
             // 纷享
            fxcm(){
                let from = this.$route.query.from;
				let allowfrom = ['xd1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
            //大白
            rm(){
                let from = this.$route.query.from;
				let allowfrom = ['xe1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
            //智以类聚
            zy(){
                let from = this.$route.query.from;
				let allowfrom = ['xf1','xf2','xf3','xf4','xf5','xf6','xf8','da1','dz2'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
            //荣铭
            rongming(){
                let from = this.$route.query.from;
				let allowfrom = ['xr1','xr2'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
            //杭州其大无外网络科技有限公司
            qida(){
                let from = this.$route.query.from;
				let allowfrom = ['xq1','xq2','xq3','ya1',,'dc1','dc2','dc3'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
            //绍兴恒健智能科技有限公司  
            hjzn(){
                let from = this.$route.query.from;
				let allowfrom = ['xn6','xn2','xn3','xn4','xn5','xz1','xz2','yb1','yb2','xj1','yb3','yb4','yb5'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
            hjzn10(){
                let from = this.$route.query.from;
				let allowfrom = ['10'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
             //图易(绍兴)信息技术有限公司
             sxytxx(){
                let from = this.$route.query.from;
				let allowfrom = ['xt1','xt2','xt3','xx1','db1','db2','db3','db4','db5','db6'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
             //图易(武汉)信息技术有限公司
             whtyxx(){
                let from = this.$route.query.from;
				let allowfrom = ['xu1','yd1','dt1','dt2'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
             //武汉圈谱文化传播有限公司
             whqp(){
                let from = this.$route.query.from;
				let allowfrom = ['xs4','xp1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
             //天津昱通祥网络科技有限公司
             tjytxwl(){
                let from = this.$route.query.from;
				let allowfrom = ['xg1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
             //贵州均好科技有限公司
             gzjhkj(){
                let from = this.$route.query.from;
				let allowfrom = ['xh1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
            
             //贵阳青青互联网科技有限公司2
             gzqqhlw(){
                let from = this.$route.query.from;
				let allowfrom = ['xy1','dq1','xh2'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
             //江西先索科技有限公司
             jxxskj(){
                let from = this.$route.query.from;
				let allowfrom = ['xw1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
             //江西好推信息技术服务有限公司
            jxhtxxjs(){
                let from = this.$route.query.from;
				let allowfrom = ['yh2','yh1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
            //重庆庚霖网络科技有限公司
            cqglwl(){
                let from = this.$route.query.from;
				let allowfrom = ['dj1','yc1','yc2','yc3'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },
            //天津星之缘网络科技有限公司
            tjxzywlkj(){
                let from = this.$route.query.from;
				let allowfrom = ['xj1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
            },

    },
    methods:{
        copyrightInformationInitStyle () {//初始化样式
                // console.log('13213',this.$route.path )
                switch (this.$route.path) {
                    case '/home_marriage_test':
                        this.copyrightInformation_color_style = 'color: #FCAF93;'
                        break; 
                    case '/home_marriage_test_v2':
                        this.copyrightInformation_color_style = 'color: #FBEC7B;'
                        break;
                    case '/home_marriage_test_v3':
                        this.copyrightInformation_color_style = 'color: #E15757;'
                        break;
                    case '/name_matching':
                        this.copyrightInformation_color_style = 'color: #c4393e;'
                        break;
                    case '/fleeting_time_fortune':
                        // this.copyrightInformation_color_style = 'color: #c4393e;'
                        break;
                
                    default:
                        break;
                }
            },
        }
}
</script>

<style lang="scss" scoped>
    div{
        .text_p{
            font-family:'MicrosoftYaHei';
            font-size: .24rem;
            text-align: center;
            // color: #FBEC7B;
            padding:0 .5rem;
        }
    }
</style>